"use client"

import { useState, useEffect } from "react"
import { enqueueSnackbar } from "notistack"
import { useRouter } from "next/navigation"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"

import { initiateLogin } from "@/actions/login"
import AuthForm from "@/components/auth_form"
import LoginFooter from "@/molecules/login_footer"
import { fixPhone, isPhoneValid } from "@/utils/validator"
import useTranslation from "@/hooks/use_translation"
import { useReduxState } from "@/hooks/use_redux_state"
import { buildContinueWithFromSearch } from "@/utils/url"
import withAnonymousRoute from "@/molecules/anonymous_route"
import { captureException } from "@sentry/nextjs"

const Login = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const [inAction, setInAction] = useState(false)
  const [, setAuthPayload] = useReduxState({ key: "authPayload" })
  const defaultPhoneLocale = useSelector((state) => state.configuration?.defaultPhoneLocale)

  
  useEffect(() => {
    setAuthPayload(null)
  }, [])

  const tfaCallback = (phone, challenge) => {
    setInAction(false)
    gtag("event", "login_to_tfa_success")
    setAuthPayload({ phone, identifier: challenge.identifier })
    const nextPage = `/tfa/?${buildContinueWithFromSearch()}`
    router.push(nextPage)
  }

  const badCallback = (status) => {
    setInAction(false)
    gtag("event", "login_to_tfa_failure")
    if (status === 404) {
      enqueueSnackbar(t("phoneNotExists"), { variant: "error", autoHideDuration: 5000 })
    } else {
      console.error(`Login failed with status ${status}`)
      captureException(new Error(`Login failed with status ${status}`))
      enqueueSnackbar(t("unexpectedError"), { variant: "error", autoHideDuration: 5000 })
    }
  }

  const onSubmit = (phone) => {
    setInAction(true)
    phone = fixPhone(phone, defaultPhoneLocale)
    initiateLogin(phone, false, (challenge) => tfaCallback(phone, challenge), badCallback)
  }

  const validate = (phone) => {
    if (!phone) {
      return t("enterPhoneNumber")
    }
    if (!isPhoneValid(phone, defaultPhoneLocale)) {
      return t("phoneIsNotValid")
    }
  }

  return (
    <>
      <Helmet>
        <title>{t("login")}</title>
      </Helmet>
      <AuthForm
        title={t("logToYourAccount")}
        message={t("generalLoginText")}
        fieldType="phone"
        fieldLabel={t("phoneNumber")}
        buttonText={t("login")}
        inAction={inAction}
        validate={validate}
        onSubmit={onSubmit}
        footer={<LoginFooter />}
      />
    </>
  )
}

export default withAnonymousRoute(Login)
