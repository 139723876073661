import PropTypes from "prop-types"
import Link from "next/link"

import useTranslation from "../hooks/use_translation"

const LoginFooter = () => {
  const { t } = useTranslation()

  return (
    <>
      {t("unregistered")}{" "}
      <Link href="/signup/" color="#5698e4">
        {t("joinTheProject")}
      </Link>
      .
    </>
  )
}

LoginFooter.propTypes = { history: PropTypes.object.isRequired }

export default LoginFooter
